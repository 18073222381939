.activities {
  display: flex;
  flex-direction: column;
  height: 100%;
  white-space: normal;
  overflow-wrap: break-word;

  .activities-follow {
    display: flex;
    flex-basis: 60px;
    justify-content: space-between;
    padding: 20px;
    background-color: var(--arxs-background-color);
  }

  .activities-body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 0 20px 0 20px;
    color: var(--arxs-secondary-color);

    .activity {
      display: flex;
      flex-direction: row;
      padding: 10px 0;
      gap: 5px;

      .avatar {
        flex-basis: 40px;
        width: 40px;
        height: 40px;
      }

      .activity-body {
        flex-basis: calc(100% - 50px);
        display: flex;
        flex-direction: column;
        gap: 5px;

        .activity-author {
          display: flex;
          flex-direction: row;
          gap: 5px;

          .activity-author-name {
            font-size: 14px;
            font-weight: bold;  
          }
    
          label {
            display: block;
            color: var(--arxs-primary-inactive-color);
            font-size: 12px;
            line-height: 16px;
          }

          .activity-remove-comment {
            visibility: hidden;
            opacity: 0;
  
            &:hover,
            &:focus {
              color: var(--arxs-icon-color);
            }
          }

          &:hover,
          &:focus {
            .activity-remove-comment {
              visibility: visible;
              opacity: 1;
              cursor: pointer;
            }
          }

          .activity-content {
            white-space: pre-wrap;
          }
        }

        .activity-content {
          .image.preview {
            width: 140px;
            height:140px;
          }

          .document {
            max-width: 280px;
          }
        }
      }
    }
  }

  .activities-header {
    flex-basis: 117px;
    border-top: var(--arxs-nav-border);
    border-bottom: var(--arxs-nav-border);
    background-color: var(--arxs-background-color);
    padding: 11px;

    textarea {
      border-width: 1px;
      border-style: solid;
      cursor: text;
    }

    .activities-header-toolbar {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      i {
        font-size: 16px;
        color: var(--arxs-secondary-color);
      }

      .activities-header-mention {
        width: 31px;
        height: 31px;
        line-height: 31px;
        text-align: center;
        margin-right: 5px;

        &:hover,
        &:focus {
          box-shadow: 0 0 10px 0 var(--arxs-border-shadow-color);
        }

        &:hover {
          cursor: pointer;
        }
      }

      .activities-header-upload {
        width: 31px;
        height: 31px;
        line-height: 31px;
        text-align: center;
        margin-right: 5px;

        &:hover,
        &:focus {
          box-shadow: 0 0 10px 0 var(--arxs-border-shadow-color);
        }
      }

      button {
        width: 31px;
        height: 31px;
        border: 0;
        border-radius: 4px;
        font-size: 12px;
        color: var(--arxs-input-background-color);
        background-color: var(--arxs-icon-color);
        cursor: pointer;

        &:hover,
        &:focus {
          box-shadow: 0 0 10px 0 var(--arxs-border-shadow-color);
        }
      }
    }
  }
}
